<script>
import customBtn from "@/components/custom-btn";
export default {
  name: "modal-component",
  components: {customBtn},
  props: {
    header: String,
    body: String,
    show: Boolean
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            {{ header }}
          </div>

          <div class="modal-body" v-html="body">

          </div>

          <div class="modal-footer">
            <custom-btn @click="$emit('close')" :title="'OK'"/>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}



.modal-container {
  margin: 20px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.modal-wrapper {
  display: table-cell;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .new__wrapper{
    background: white;
    padding: 10px;
    margin: 15px;
    border-radius: 14px;
  }
  .news__block > div {
    width: calc(100%);
    margin-top: 10px;
    box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);

  }

}
@media (min-width: 1240px)  {
  .modal-wrapper {
    vertical-align: middle;
  }
  .modal-container{
    width: 900px;
  }
}
</style>
