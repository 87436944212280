const localstorageSet = (key, data) => {
    return localStorage.setItem(key, data);
}

const localstorageGet = (key) => {
    return localStorage.getItem(key);
}

const mapCar = (data) => {
  return {
      vin_code: data.vim,
      lastFourDigits: "",
      year : data.yearBorn,
      parking: 0,
      expedition: 680,
      engType: data.typeEng,
      name : data.name + " " + data.bstl,
      capacity : data.eng,
      cert : data.titleCheck,
      lic : data.lic,
      renta : data.renta,
      lotId : data.lotId,
      salvageId : data.salvageId,
      priceWithInsurense: 0,
      subL: data.subL,
      accCapacity: 0,
      evacuation: 0,
      certP: 0,
      planCompany: 0,
      shippingKL : 600,
      saleStatus: data.saleStatus,
      parkingKl: 50,
      grandPrice: 0,
      forAnalytics: data?.forAnalytics ?? {},
      price : data.priceNow,
  }
}

const getLiveBindCopart = (bind) => {
    let liveBind = 0;
    if (bind >= 0.01 && bind <= 99.99) {
        liveBind = 0;
    }
    if (bind >= 100 && bind <= 499.99) {
        liveBind = 50;
    }
    if (bind >= 500 && bind <= 999.99) {
        liveBind = 65;
    }
    if (bind >= 1000 && bind <= 1499.99) {
        liveBind = 85;
    }
    if (bind >= 1500 && bind <= 1999.99) {
        liveBind = 95;
    }
    if (bind >= 2000 && bind <= 3999.99) {
        liveBind = 110;
    }
    if (bind >= 4000 && bind <= 5999.99) {
        liveBind = 125;
    }
    if (bind >= 6000 && bind <= 7999.99) {
        liveBind = 145;
    }
    if (bind >= 8000 && bind <= 10000000) {
        liveBind = 160;
    }
    return Math.ceil(liveBind);
};

const getFreeACopart = (bind) => {
    let freeA = 0.0;
    if (bind >= 0.01 && bind <= 99.99) {
        freeA = 1;
    }
    if (bind >= 100 && bind <= 199.99) {
        freeA = 25;
    }
    if (bind >= 200 && bind <= 299.99) {
        freeA = 60;
    }
    if (bind >= 300 && bind <= 349.99) {
        freeA = 85;
    }
    if (bind >= 350 && bind <= 399.99) {
        freeA = 100;
    }
    if (bind >= 400 && bind <= 449.99) {
        freeA = 125;
    }
    if (bind >= 450 && bind <= 499.99) {
        freeA = 135;
    }
    if (bind >= 500 && bind <= 549.99) {
        freeA = 145;
    }
    if (bind >= 550 && bind <= 599.99) {
        freeA = 155;
    }
    if (bind >= 600 && bind <= 699.99) {
        freeA = 170;
    }
    if (bind >= 700 && bind <= 799.99) {
        freeA = 195;
    }
    if (bind >= 800 && bind <= 899.99) {
        freeA = 215;
    }
    if (bind >= 900 && bind <= 999.99) {
        freeA = 230;
    }
    if (bind >= 1000 && bind <= 1199.99) {
        freeA = 250;
    }
    if (bind >= 1200 && bind <= 1299.99) {
        freeA = 270;
    }
    if (bind >= 1300 && bind <= 1399.99) {
        freeA = 285;
    }
    if (bind >= 1400 && bind <= 1499.99) {
        freeA = 300;
    }
    if (bind >= 1500 && bind <= 1599.99) {
        freeA = 315;
    }
    if (bind >= 1600 && bind <= 1699.99) {
        freeA = 330;
    }
    if (bind >= 1700 && bind <= 1799.99) {
        freeA = 350;
    }
    if (bind >= 1800 && bind <= 1999.99) {
        freeA = 370;
    }
    if (bind >= 2000 && bind <= 2399.99) {
        freeA = 390;
    }
    if (bind >= 2400 && bind <= 2499.99) {
        freeA = 425;
    }
    if (bind >= 2500 && bind < 2999.99) {
        freeA = 460;
    }
    if (bind >= 3000 && bind < 3499.99) {
        freeA = 505;
    }
    if (bind >= 3500 && bind <= 3999.99) {
        freeA = 555;
    }
    if (bind >= 4000 && bind <= 4499.99) {
        freeA = 600;
    }
    if (bind >= 4500 && bind <= 4999.99) {
        freeA = 625;
    }
    if (bind >= 5000 && bind <= 5499.99) {
        freeA = 650;
    }
    if (bind >= 5500 && bind <= 5999.99) {
        freeA = 675;
    }
    if (bind >= 6000 && bind <= 6499.99) {
        freeA = 700;
    }
    if (bind >= 6500 && bind <= 6999.99) {
        freeA = 720;
    }
    if (bind >= 7000 && bind <= 7499.99) {
        freeA = 755;
    }
    if (bind >= 7500 && bind <= 7999.99) {
        freeA = 775;
    }
    if (bind >= 8000 && bind <= 8499.99) {
        freeA = 800;
    }
    if (bind >= 8500 && bind <= 9999.99) {
        freeA = 820;
    }
    if (bind >= 10000 && bind <= 11499.99) {
        freeA = 850;
    }
    // if (bind >= 10500 && bind <= 10999.99) {
    //     freeA = 730;
    // }
    // if (bind >= 11000 && bind <= 11499.99) {
    //     freeA = 745;
    // }
    if (bind >= 11500 && bind <= 11999.99) {
        freeA = 860;
    }
    if (bind >= 12000 && bind <= 12499.99) {
        freeA = 875;
    }
    if (bind >= 12500 && bind <= 14999.99) {
        freeA = 890;
    }
    if (bind >= 15000 && bind <= 10000000.0) {
        freeA = Math.ceil(bind * 0.06);
    }
    return Math.ceil(freeA);
};

const getLiveBindIAAI = (bind) => {
    let liveBind = 0;
    if (bind >= 0.01 && bind <= 99.99) {
        liveBind = 0;
    }
    if (bind >= 100 && bind <= 499.99) {
        liveBind =50;
    }
    if (bind >= 500 && bind <= 999.99) {
        liveBind = 65;
    }
    if (bind >= 1000 && bind <= 1499.99) {
        liveBind = 85;
    }
    if (bind >= 1500 && bind <= 1999.99) {
        liveBind = 95;
    }
    if (bind >= 2000 && bind <= 3999.99) {
        liveBind = 110;
    }
    if (bind >= 4000 && bind <= 5999.99) {
        liveBind = 125;
    }
    if (bind >= 6000 && bind <= 7999.99) {
        liveBind = 145;
    }
    if (bind >= 8000 && bind <= 10000000) {
        liveBind = 160;
    }

    return Math.ceil(liveBind);
};

const getFreeAIAAI = (bind) => {
    let freeA = 0;
    if (bind >= 0.01 && bind <= 99.99) {
        freeA = 1;
    }
    if (bind >= 100 && bind <= 199.99) {
        freeA = 25;
    }
    if (bind >= 200 && bind <= 299.99) {
        freeA = 60;
    }
    if (bind >= 300 && bind <= 349.99) {
        freeA = 85;
    }
    if (bind >= 350 && bind <= 399.99) {
      freeA = 100;
    }
    if (bind >= 400 && bind <= 449.99) {
        freeA = 125;
    }
    if (bind >= 450 && bind <= 499.99) {
      freeA = 135;
    }
    if (bind >= 500 && bind <= 549.99) {
        freeA = 145;
    }
    if (bind >= 550 && bind <= 599.99) {
        freeA = 155;
    }
    if (bind >= 600 && bind <= 699.99) {
        freeA = 170;
    }
    if (bind >= 700 && bind <= 799.99) {
        freeA = 195;
    }
    if (bind >= 800 && bind <= 899.99) {
        freeA = 215;
    }
    if (bind >= 900 && bind <= 999.99) {
        freeA = 230;
    }
    if (bind >= 1000 && bind <= 1199.99) {
        freeA = 250;
    }
    // if (bind >= 1100 && bind <= 1199.99) {
    //     freeA = 230;
    // }
    if (bind >= 1200 && bind <= 1299.99) {
        freeA = 270;
    }
    if (bind >= 1300 && bind <= 1399.99) {
        freeA = 285;
    }
    if (bind >= 1400 && bind <= 1499.99) {
        freeA = 300;
    }
    if (bind >= 1500 && bind <= 1599.99) {
        freeA = 315;
    }
    if (bind >= 1600 && bind <= 1699.99) {
        freeA = 330;
    }
    if (bind >= 1700 && bind <= 1799.99) {
        freeA = 350;
    }
    if (bind >= 1800 && bind <= 1999.99) {
        freeA = 370;
    }
    if (bind >= 2000 && bind <= 2399.99) {
        freeA = 390;
    }
    // if (bind >= 2200 && bind <= 2399.99) {
    //     freeA = 350;
    // }
    if (bind >= 2400 && bind <= 2499.99) {
        freeA = 425;
    }
    if (bind >= 2500 && bind <= 2999.99) {
        freeA = 460;
    }
    // if (bind >= 2800 && bind <= 2999.99) {
    //     freeA = 400;
    // }
    if (bind >= 3000 && bind < 3499.99) {
        freeA = 505;
    }
    if (bind >= 3500 && bind < 3999.99) {
        freeA = 555;
    }
    if (bind >= 4000 && bind < 4499.99) {
        freeA = 600;
    }
    if (bind >= 4500 && bind <= 4999.99) {
        freeA = 625;
    }
    if (bind >= 5000 && bind <= 5499.99) {
        freeA = 650;
    }
    if (bind >= 5500 && bind <= 5999.99) {
        freeA = 675;
    }
    if (bind >= 6000 && bind <= 6499.99) {
        freeA = 700;
    }
    if (bind >= 6500 && bind <= 6999.99) {
        freeA = 720;
    }
    if (bind >= 7000 && bind <= 7499.99) {
        freeA = 755;
    }
    if (bind >= 7500 && bind <= 7999.99) {
      freeA = 775;
    }
    if (bind >= 8000 && bind <= 8499.99) {
        freeA = 800;
    }
    if (bind >= 8500 && bind <= 9999.99) {
        freeA = 820;
    }
    if (bind >= 10000 && bind <= 11499.99) {
        freeA = 850;
    }
    if (bind >= 11500 && bind <= 11999.0) {
      freeA = 860;
    }
    if (bind >= 12000 && bind <= 12499.0) {
      freeA = 875;
    }
    if (bind >= 12500 && bind <= 14999.0) {
      freeA = 890;
    }
    if (bind >= 15000) {
        freeA = Math.ceil(bind * 0.06);
    }
    return Math.ceil(freeA);
};

const getLiveBindCopartClosed = (bind) => {
    let liveBind = 0;
    if (bind >= 0.01 && bind <= 99.99) {
        liveBind = 0;
    }
    if (bind >= 100 && bind <= 499.99) {
        liveBind = 49;
    }
    if (bind >= 500 && bind <= 999.99) {
        liveBind = 59;
    }
    if (bind >= 1000 && bind <= 1499.99) {
        liveBind = 79;
    }
    if (bind >= 1500 && bind <= 1999.99) {
        liveBind = 89;
    }
    if (bind >= 2000 && bind <= 3999.99) {
        liveBind = 99;
    }
    if (bind >= 4000 && bind <= 5999.99) {
        liveBind = 109;
    }
    if (bind >= 6000 && bind <= 7999.99) {
        liveBind = 139;
    }
    if (bind >= 8000 && bind <= 10000000) {
        liveBind = 149;
    }
    return Math.ceil(liveBind);
};

const getFreeACopartClosed = (bind) => {
    let freeA = 0.0;
    if (bind >= 0.01 && bind <= 99.99) {
        freeA = 1;
    }
    if (bind >= 100 && bind <= 199.99) {
        freeA = 25;
    }
    if (bind >= 200 && bind <= 299.99) {
        freeA = 60;
    }
    if (bind >= 300 && bind <= 349.99) {
        freeA = 80;
    }
    if (bind >= 350 && bind <= 399.99) {
        freeA = 90;
    }
    if (bind >= 400 && bind <= 449.99) {
        freeA = 120;
    }
    if (bind >= 450 && bind <= 499.99) {
        freeA = 130;
    }
    if (bind >= 500 && bind <= 549.99) {
        freeA = 140;
    }
    if (bind >= 550 && bind <= 599.99) {
        freeA = 150;
    }
    if (bind >= 600 && bind <= 699.99) {
        freeA = 165;
    }
    if (bind >= 700 && bind <= 799.99) {
        freeA = 185;
    }
    if (bind >= 800 && bind <= 899.99) {
        freeA = 200;
    }
    if (bind >= 900 && bind <= 999.99) {
        freeA = 215;
    }
    if (bind >= 1000 && bind <= 1199.99) {
        freeA = 230;
    }
    if (bind >= 1200 && bind <= 1299.99) {
        freeA = 255;
    }
    if (bind >= 1300 && bind <= 1399.99) {
        freeA = 275;
    }
    if (bind >= 1400 && bind <= 1499.99) {
        freeA = 280;
    }
    if (bind >= 1500 && bind <= 1599.99) {
        freeA = 290;
    }
    if (bind >= 1600 && bind <= 1699.99) {
        freeA = 305;
    }
    if (bind >= 1700 && bind <= 1799.99) {
        freeA = 315;
    }
    if (bind >= 1800 && bind <= 1999.99) {
        freeA = 325;
    }
    if (bind >= 2000 && bind <= 2399.99) {
        freeA = 355;
    }
    if (bind >= 2400 && bind <= 2499.99) {
        freeA = 380;
    }
    if (bind >= 2500 && bind < 2999.99) {
        freeA = 400;
    }
    if (bind >= 3000 && bind < 3499.99) {
        freeA = 450;
    }
    if (bind >= 3500 && bind <= 3999.99) {
        freeA = 495;
    }
    if (bind >= 4000 && bind <= 4499.99) {
        freeA = 575;
    }
    if (bind >= 4500 && bind <= 4999.99) {
        freeA = 600;
    }
    if (bind >= 5000 && bind <= 6999.99) {
        freeA = 650;
    }
    if (bind >= 7000 && bind <= 7499.99) {
        freeA = 675;
    }
    if (bind >= 7500 && bind <= 9999.99) {
        freeA = 685;
    }
    if (bind >= 10000 && bind <= 10499.99) {
        freeA = 715;
    }
    if (bind >= 10500 && bind <= 10999.99) {
        freeA = 730;
    }
    if (bind >= 11000 && bind <= 11499.99) {
        freeA = 745;
    }
    if (bind >= 11500 && bind <= 11999.99) {
        freeA = 760;
    }
    if (bind >= 12000 && bind <= 12499.99) {
        freeA = 775;
    }
    if (bind >= 12500 && bind <= 14999.99) {
        freeA = 790;
    }
    if (bind >= 15000 && bind <= 10000000.0) {
        freeA = Math.ceil(bind * 0.0575);
    }
    return Math.ceil(freeA);
};

const getLiveBindIAAIClosed = (bind) => {
    let liveBind = 0;
    if (bind >= 0.01 && bind <= 99.99) {
        liveBind = 0;
    }
    if (bind >= 100 && bind <= 499.99) {
        liveBind = 49;
    }
    if (bind >= 500 && bind <= 999.99) {
        liveBind = 59;
    }
    if (bind >= 1000 && bind <= 1499.99) {
        liveBind = 79;
    }
    if (bind >= 1500 && bind <= 1999.99) {
        liveBind = 89;
    }
    if (bind >= 2000 && bind <= 3999.99) {
        liveBind = 99;
    }
    if (bind >= 4000 && bind <= 5999.99) {
        liveBind = 109;
    }
    if (bind >= 6000 && bind <= 7999.99) {
        liveBind = 139;
    }
    if (bind >= 8000 && bind <= 10000000) {
        liveBind = 149;
    }
    return Math.ceil(liveBind);
};

const getFreeAIAAIClosed = (bind) => {
    let freeA = 0;
    if (bind >= 0.01 && bind <= 49.99) {
        freeA = 25;
    }
    if (bind >= 50 && bind <= 99.99) {
        freeA = 45;
    }
    if (bind >= 100 && bind <= 199.99) {
        freeA = 80;
    }
    if (bind >= 200 && bind <= 299.99) {
        freeA = 120;
    }
    if (bind >= 300 && bind <= 399.99) {
        freeA = 120;
    }
    // if (bind >= 350 && bind <= 399.99) {
    //   freeA = 90;
    // }
    if (bind >= 400 && bind <= 499.99) {
        freeA = 170;
    }
    // if (bind >= 450 && bind <= 499.99) {
    //   freeA = 130;
    // }
    if (bind >= 500 && bind <= 549.99) {
        freeA = 195;
    }
    if (bind >= 550 && bind <= 599.99) {
        freeA = 195;
    }
    if (bind >= 600 && bind <= 699.99) {
        freeA = 225;
    }
    if (bind >= 700 && bind <= 799.99) {
        freeA = 245;
    }
    if (bind >= 800 && bind <= 899.99) {
        freeA = 265;
    }
    if (bind >= 900 && bind <= 999.99) {
        freeA = 290;
    }
    if (bind >= 1000 && bind <= 1099.99) {
        freeA = 340;
    }
    if (bind >= 1100 && bind <= 1199.99) {
        freeA = 355;
    }
    if (bind >= 1200 && bind <= 1299.99) {
        freeA = 370;
    }
    if (bind >= 1300 && bind <= 1399.99) {
        freeA = 385;
    }
    if (bind >= 1400 && bind <= 1499.99) {
        freeA = 400;
    }
    if (bind >= 1500 && bind <= 1599.99) {
        freeA = 415;
    }
    if (bind >= 1600 && bind <= 1699.99) {
        freeA = 430;
    }
    if (bind >= 1700 && bind <= 1799.99) {
        freeA = 445;
    }
    if (bind >= 1800 && bind <= 1999.99) {
        freeA = 460;
    }
    if (bind >= 2000 && bind <= 2199.99) {
        freeA = 480;
    }
    if (bind >= 2200 && bind <= 2399.99) {
        freeA = 495;
    }
    if (bind >= 2400 && bind <= 2599.99) {
        freeA = 510;
    }
    if (bind >= 2600 && bind <= 2799.99) {
        freeA = 525;
    }
    if (bind >= 2800 && bind <= 2999.99) {
        freeA = 550;
    }
    if (bind >= 3000 && bind < 3499.99) {
        freeA = 650;
    }
    if (bind >= 3500 && bind < 3999.99) {
        freeA = 700;
    }
    if (bind >= 4000 && bind < 4499.99) {
        freeA = 725;
    }
    if (bind >= 4500 && bind <= 4999.99) {
        freeA = 750;
    }
    if (bind >= 5000 && bind <= 5999.99) {
        freeA = 775;
    }
    if (bind >= 6000 && bind <= 6999.99) {
        freeA = 800;
    }
    if (bind >= 7000 && bind <= 7999.99) {
        freeA = 825;
    }
    // if (bind >= 7500 && bind <= 9999.99) {
    //   freeA = 685;
    // }\
    if (bind >= 8000 && bind <= 9999.99) {
        freeA = 850;
    }
    if (bind >= 10000 && bind <= 14999.99) {
        freeA = 900;
    }
    // if (bind >= 10500 && bind <= 10999.0) {
    //   freeA = 730;
    // }
    // if (bind >= 11000 && bind <= 11499.0) {
    //   freeA = 745;
    // }
    // if (bind >= 11500 && bind <= 11999.0) {
    //   freeA = 760;
    // }
    // if (bind >= 12000 && bind <= 12499.0) {
    //   freeA = 775;
    // }
    // if (bind >= 12500 && bind <= 14999.0) {
    //   freeA = 790;
    // }
    if (bind >= 15000) {
        freeA = Math.ceil(bind * 0.075);
    }
    return Math.ceil(freeA);
};

function stringToSlug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export {
    stringToSlug,
    localstorageSet,
    localstorageGet,
    mapCar,
    getLiveBindCopart,
    getFreeACopart,
    getLiveBindIAAI,
    getFreeAIAAI,
    getLiveBindCopartClosed,
    getFreeACopartClosed,
    getLiveBindIAAIClosed,
    getFreeAIAAIClosed
};

