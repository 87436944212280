import { createRouter, createWebHistory } from 'vue-router'

import Login from "@/pages/Login"
import Calculator from "@/pages/Calculator"
import UsaPort from "@/pages/UsaPort"
import KlaipedaPort from "@/pages/KlaipedaPort"
import News from "@/pages/News";
import shareResult from "@/pages/shareResult";
import CarFax from "@/pages/CarFax";
import axiosClient from "@/axios/axios";
import VinDecoder from "@/pages/VinDecoder.vue";
import {localstorageSet} from "@/helpers/helper";
const routes = [
    {
        path: '/',
        children : [
            {
                path: '/login',
                name: 'login',
                meta: {
                    isPrivate : false
                },
                component: Login
            },
            {
                path: '/share-result',
                name: 'share-result',
                meta: {
                    isPrivate : false
                },
                component: shareResult
            },
            {
                path: '/calculator',
                name: 'calculator',
                meta: {
                    isPrivate : true
                },
                component: Calculator
            },
            {
                path: '/usa-port',
                name: 'usa-port',
                meta: {
                    isPrivate : true
                },
                component: UsaPort
            },
            {
                path: '/klaipeda-port',
                name: 'klaipeda-port',
                meta: {
                    isPrivate : true
                },
                component: KlaipedaPort
            },
            {
                path: '/vin-decoder/:vin',
                name: 'vin-decoder',
                meta: {
                    isPrivate : true
                },
                component: VinDecoder
            },
            {
                path: '/news',
                name: 'news',
                meta: {
                    isPrivate : true
                },
                component: News
            },
            {
                path: '/car-fax',
                name: 'car-fax',
                meta: {
                    isPrivate : true
                },
                component: CarFax
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async(to,from, next) => {
    let isAuth = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null
    if(to.meta.isPrivate && !isAuth){
        return next({ name: 'login' })
    }
    if(to.name === 'login' && isAuth){
        return next({ name: 'calculator' })
    }
    if(isAuth) {
        await axiosClient.get('/me').then(({data}) => {
            localstorageSet('user',JSON.stringify(data))
        }).catch(({response}) => {
            if (response.status === 401) {
                localStorage.removeItem('token')
            }
        });
    }


    if(to.name === undefined){
        return next({ name: 'calculator' })
    }
    return next()
})

export default router;
