import {localstorageGet} from "@/helpers/helper";
import axios from "axios";
const APP_BASE_URL = "https://back-end.mad-dev.pp.ua/api";

const axiosClient = axios.create();
axiosClient.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': `Bearer ${localstorageGet('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        config.baseURL = APP_BASE_URL
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosClient.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status  && error.response.status === 401) {

        if(!error.response.config.url.includes('api/login')){
            localStorage.removeItem('token')
            window.location.reload();
        }

    }
    return Promise.reject(error)

})

export default axiosClient;
