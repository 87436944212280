import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/web"
import Notifications from '@kyvg/vue3-notification'
import {TooltipDirective} from "@/directives/tooltip";
const app = createApp(App)

app.directive('v-tooltip',TooltipDirective)
app.use(router)
app.use(Notifications)
app.mount('#app')
