<template>
  <div class="input__wrapper">
    <div class="input__block">
      <input class="input input-font" :name="name" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder"/>
    </div>
    <div class="error__block" v-if="error !== ''">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "custom-input",
  props: {
    'type' : {
      required: true,
    },
    'modelValue' : {
      required : true,
    },
    'placeholder' : {
      required : true
    },
    'name' : {
      required : false,
      default : '',
    },
    'error' : {
      required : false
    },
  }
}
</script>

<style scoped>
.input__wrapper{
  margin-bottom: 20px;
}
.error__block{
  display: flex;
  justify-content: right;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #DC5050;
}
.input__block{
  display: flex;
}
.input{
  background: #FFFFFF;
  border: 1px solid #D0D3D7;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
}

.input-font{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
</style>
