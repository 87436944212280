<template>
  <div class="klaipeda-port__wrapper">
    <div class="klaipeda-port__header">
      Вставте VIN код автомобіля
    </div>

    <div class="klaipeda-port__action">
      <div>
        <input class="input" type="text" v-model="vin" placeholder="Він код">
      </div>
      <div v-if="!scanLoader">
        <custom-btn :title="'Сканувати'" :placeholder="'1GYS4HKJ3GR176843'" @click="getKlaipedaPort"></custom-btn>
      </div>
      <div class="center" v-else>
        <loaderSmall/>
      </div>
    </div>

    <div class="klaipeda-port__data" v-if="Object.keys(dataParse).length > 0">
      <div class="inline-list">
        <div class="main-img">
          <img :src="this.mainImg" alt="">
        </div>
        <div class="img-list">
          <div v-for="(img,key) in images" :key="key">
            <img :src="img" alt="">
          </div>
        </div>
        <div class="info-list">
          <div class="info-header">Інформація</div>
          <div class="info__capacity" v-for="(data,key) in dataParse" :key="key">
            <div class="block1">
              {{ key }}
            </div>
            <div class="line black"></div>
            <div class="block2">
              <b>{{ data }}</b>
            </div>
          </div>

        </div>
      </div>
      <div>
        <a :href="zipLink" download>Зберегти всі фото</a>
      </div>
    </div>
  </div>
</template>

<script>
import customBtn from "@/components/custom-btn";
import loaderSmall from "@/components/loader-small";
import axiosClient from "@/axios/axios";
export default {
  name: "klaipeda-port-index",
  components: {customBtn,loaderSmall},
  data(){
    return {
      scanLoader : false,
      vin: "",
      images: [],
      dataParse: "",
      zipLink: "",
      mainImg: "",
    }
  },
  methods:{
    getKlaipedaPort()
      {
        this.scanLoader = true;
        axiosClient.get('/get-klaipeda/' + this.vin).then((response) => {
          if(response.data.images.length > 0){
            this.images = response.data.images;
            this.zipLink = response.data.zip;
            this.mainImg = response.data.images[0];
            this.dataParse = response.data.data;
          }else{
            this.$notify({
              title: "Системне повідомлення!",
              type: 'error',
              text: "Нічого не вийшло, спробуйте з другим Vin кодом!",
            })
          }
          this.scanLoader = false;
        }).catch(() => {
          this.$notify({
            title: "Системне повідомлення!",
            type: 'error',
            text: "Нічого не вийшло, спробуйте з другим Vin кодом!",
          })
          this.scanLoader = false;
        });
      }
    }
  }
</script>

<style scoped>
.klaipeda-port__wrapper{
  max-width: 1280px;
  margin: 0 auto;
}
.klaipeda-port__action{
  max-width: 500px;
  margin: 15px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.klaipeda-port__action > div:first-child{
  width: 77%;
  display: flex;
}
.klaipeda-port__action > div:last-child{
  width: 22%;
}
.klaipeda-port__header{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  color: #000000;
  max-width: 500px;
  margin: 0 auto;
}
.klaipeda-port__data{
  background: #FFFFFF;
  border-radius: 10px;

  padding: 20px;
  margin-top: 10px;
}
.klaipeda-port__data .inline-list{
  display: flex;
  justify-content: space-between;
}
.main-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.info__capacity {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.block1,
.block2 {
  position: relative;
  z-index: 2;
  bottom: -5px;
  padding: 2px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 144% */


  color: #000000;
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #D9D9D9;
  z-index: 1;
}
.info__capacity {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.img-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 600px;
  height: 500px;
  overflow-y: scroll;
}
.img-list > div{
  width: 126px;
  height: 126px;
}
.img-list img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.klaipeda-port__data > .inline-list > div:nth-child(1){
  width: calc(35% - 15px);
}
.klaipeda-port__data > .inline-list > div:nth-child(2){
  width: calc(35% - 15px);
}
.klaipeda-port__data > .inline-list > div:nth-child(3){
  width: calc(30% - 20px);
}
.info-header{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 40px;
  text-align: left;
  color: #000000;
}
</style>
