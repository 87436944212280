<template>
  <div class="calculator__wrapper">
    <div class="calculator__main__block d-flex justify-center">
      <i v-if="Object.keys(vinDecode?.data ?? {}).length === 0" class="animate-spin fa fa-solid fa-spinner"></i>

      <div class="mr-5" v-else>
        <div v-for="(decode, key) in Object.keys(vinDecode?.data ?? {})" :key="key">
          <hr class="mb-2">
          <div class="d-flex justify-between">
            <div class="font-bold min-w-[200px] text-left">{{ decode }}</div>
            <div>{{ vinDecode?.data[decode] }}</div>
          </div>
          <hr class="mt-2">
        </div>
      </div>
<!--      <div>-->
<!--        <div v-for="(decode, key) in Object.keys(secondPath ?? {})" :key="key" >-->
<!--          <hr class="mb-2">-->
<!--          <div class="d-flex justify-between">-->
<!--            <div class="font-bold">{{ decode }}</div>-->
<!--            <div>{{ secondPath[decode] }}</div>-->
<!--          </div>-->
<!--          <hr class="mt-2">-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import helperAuction from "@/helpers/helper-auction";

export default {
  name: "vin-decoder-component",
  data() {
    return {
      vinDecode: {},
      vin: "",
    }
  },
  async mounted() {
    this.vin = this.$route.params.vin
    this.vinDecode = await helperAuction.getVinDecoder(this.vin)
    // this.firstPath = {};
    // this.secondPath = {};
    // const keys = Object.keys(this.vinDecode.data);
    // const halfwayPoint = Math.ceil(keys.length / 2);
    //
    // // Разделяем ключи на две группы
    // const firstHalfKeys = keys.slice(0, halfwayPoint);
    // const secondHalfKeys = keys.slice(halfwayPoint);
    //
    // firstHalfKeys.forEach(key => {
    //   this.firstPath[key] = this.vinDecode.data[key];
    // });
    //
    // secondHalfKeys.forEach(key => {
    //   this.secondPath[key] = this.vinDecode.data[key];
    // });

  },
  methods: {}

}
</script>

<style scoped>
.copy__btn:hover svg path {
  fill: #ffffff;
}

.calculator__wrapper {
  position: relative;
  padding-bottom: 10px;
}


.main img {
  width: 200px;
}

.t1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.t2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.t3 img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.t4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.t5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.t6 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-images > div {
  margin-right: 10px;
  margin-bottom: 10px;
}

.search__car__block .input {
  padding: 20px 50px 20px 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input:focus {
  outline: 1px solid #88ABFF;
}


.share-icons-list > div {
  cursor: pointer;
  margin-right: 10px;
}

.calculator__wrapper {
  padding: 10px;
  margin: 10px;
}

.search__car__wrapper > div:first-child {
  width: 100%;
  margin: 10px auto;
}

.search__car__wrapper > div:last-child {
  width: 100%;
}

.search__car__wrapper > div:first-child {
  width: 78%;
}

.search__car__wrapper > div:last-child {
  width: 20%;
}

.calculator__main__block {
  max-width: 1240px;
  margin: 0px auto;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0 16px 16px 16px;
  box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);
  width: 100%;
}


</style>
