<template>
  <notifications position="top left"/>

  <header-index v-if="currentRoute !== 'share-result'"/>
  <div id="container" v-if="currentRoute !== 'share-result'">
    <router-view></router-view>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import header from "@/components/header";

export default {
  name: 'App',
  components: {
    'header-index' : header
  },
  watch:{
    $route (to){
      this.currentRoute = to.name;

    }
  },
  mounted() {

    if(this.currentRoute !== 'share-result'){
      (function(){
        let elem = document.getElementById('body'),
            startX = -20,
            startY = -20,
            w = document.documentElement.offsetWidth,
            h = document.documentElement.offsetHeight;

        if(elem) {
          document.body.addEventListener('mousemove', function (evt) {
            let posX = Math.round(evt.clientX / w * startX)
            let posY = Math.round(evt.clientY / h * startY)
            elem.style.backgroundPosition = "calc(99% - " + posX + 'px )' + "calc(80% - " + posY + "px)";
          })
        }
      })()

    }

  },
  data(){
    return {
      currentRoute : ""
    }
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  background-image: url('~@/assets/logo.png');
}
.background {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
</style>
