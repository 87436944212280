
import axiosClient from "@/axios/axios";

const token = async () => {
    const {data} = await axiosClient.post('auction-api-auth')
    localStorage.setItem('auction-token',data.token);
    return data.token;
}

const getVin = async (lotId, salvageId, source) => {
    const {data} = await axiosClient.post('vin-code',{
        token : localStorage.getItem('auction-token'),
        lotId : lotId,
        salvageId : salvageId,
        source : source,
    })
    return data;
}

const getVinDecoder = async (vin) => {
    const {data} = await axiosClient.post('vin-decoder',{
        token : localStorage.getItem('auction-token'),
        vin : vin,
    })
    return data;
}

const getVinHistories = async (vin) => {
    const {data} = await axiosClient.post('vin-histories',{
        token : localStorage.getItem('auction-token'),
        vin : vin,
    })
    return data;
}

const getLotDetails = async (vin, lotId, source) => {
    const {data} = await axiosClient.post('lot-details',{
        token : localStorage.getItem('auction-token'),
        vin : vin,
        number : lotId,
        source : source,
    })
    return data;
}

const getAvgBids = async (dataAnalytics) => {
    const {data} = await axiosClient.post('avg-bids',{
        token : localStorage.getItem('auction-token'),
        data : dataAnalytics,
    })
    return data;
}
export default {token,getVin,getVinHistories,getVinDecoder,getAvgBids,getLotDetails};