<template>
  <div class="wrapper__btn">
    <div>
      <button class="btn btn-font" :class="{
        extra : large
      }">{{ title }}</button>
    </div>
    <div class="btn__error" v-if="error !== ''">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "custom-btn",
  props: {
    title: {
      required : true,
    },
    error: {
      required : false,
      default: ""
    },
    large: {
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.btn{
  background: #000000;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  border: none;
}
.btn:hover{
  background: #292929;
}
.extra{
  width: 100%;
}
.wrapper__btn{
  width: 100%;
}
.btn-font{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #FFFFFF;
}
.btn__error{
  display: flex;
  justify-content: right;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #DC5050;
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .wrapper__btn{
    margin: 10px auto;
  }
}
@media (min-width: 1240px)  {
}
</style>
