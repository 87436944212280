<template>
  <div class="new__wrapper">
    <div class="new__header">
      Останні новини
    </div>
    <div class="news__block" v-if="!load">
        <div class="post" v-for="post in posts" :key="post.id" @click="selectPost(post)">
          <div class="img">
            <img src="~@/assets/logo-lion.png" alt="">
          </div>
          <div class="header-post">
            <div class="time">
              {{ moment(post.created_at).format("YYYY/MM/DD")}}
            </div>
            <div class="title">
              {{ post.title }}
            </div>
          </div>
        </div>
    </div>
    <div class="news__block" v-else>
      <div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div><div>
        <Skeleton height="150px"></Skeleton>
      </div>
    </div>
    <modal v-if="Object.keys(selectedPost).length > 0"
           :show="Object.keys(selectedPost).length > 0"
           :body="selectedPost.post.content"
           :header="selectedPost.post.title"
           @close="selectedPost={}"
    />
  </div>
</template>

<script>
import modal from "@/components/modal";
import axiosClient from "@/axios/axios";
import {Skeleton} from "vue-loading-skeleton";
import {logo} from "@/helpers/helper-svg";
import moment from "moment";
export default {
  name: "news-index",
  components:{Skeleton,modal},
  data(){
    return {
      load: false,
      logo : logo,
      moment: moment,
      selectedPost : {},
      posts: [],
    }
  },
  mounted() {
    this.getPosts();
  },
  methods:{
    selectPost(post){
      this.selectedPost = post;
    },
    getPosts: async function()
    {
      this.load = true;
      axiosClient.get('/getPosts').then((response)=>{
        this.posts = response.data;
        this.load = false;
      });

    }
  }
}
</script>

<style scoped>
.header-post{
  background: #FFFFFF;
  border-top: 0.5px solid black;
  border-radius: 0 0 10px 10px;
  padding: 20px;
}
.new__wrapper{
  max-width: 1420px;
  margin: 0 auto;
  position: relative;
}
.new__header{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  color: #000000;
  text-align: left;
}

.news__block{
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
.news__block > div{

  cursor: pointer;
}
.img img{
  width: 100px;
}
.title{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.img{
  background: white;
  padding: 15px;
  border-radius: 10px 10px 0 0 ;
}
.time{
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .new__wrapper{
    background: white;
    padding: 10px;
    margin: 15px;
    border-radius: 14px;
  }
  .news__block > div {
    width: calc(100%);
    margin-top: 10px;
    box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);

  }

}
@media (min-width: 1240px)  {
  .news__block > div{
    width: calc(100% / 5 - 20px);
    margin-right: 20px;
    margin-top: 20px;
  }
}
</style>
